import AppRoutes from "./Routes/appRoutes";
import "react-activity/dist/library.css";
import "animate.css/animate.min.css";

function App() {
  // return <AppRoute />;
  return;
}

export default App;
